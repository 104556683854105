export const VIEW_ACTUAL = 'VIEW_ACTUAL'
export const ENTER_ADD_RESERVATION = 'ENTER_ADD_RESERVATION'
export const ENTER_EDIT_RESERVATION = 'ENTER_EDIT_RESERVATION'
export const ENTER_CLONE_RESERVATION = 'ENTER_CLONE_RESERVATION'
export const REGISTER_CALLBACK_HANDLERS = 'REGISTER_CALLBACK_HANDLERS'

export const CLOSE_SLIDEOUT = 'CLOSE_SLIDEOUT'
export const SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS = 'SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS'

export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER'
export const CLICK_NOTIFICATION_BANNER_ACTION = 'CLICK_NOTIFICATION_BANNER_ACTION'
export const CLOSE_NOTIFICATION_BANNER = 'CLOSE_NOTIFICATION_BANNER'

export const SAVE_ATTACHMENT_START = 'SAVE_ATTACHMENT_START'
export const SAVE_ATTACHMENT_SUCCESS = 'SAVE_ATTACHMENT_SUCCESS'
export const SAVE_ATTACHMENT_FAIL = 'SAVE_ATTACHMENT_FAIL'

export const REGISTER_ATTACHMENTS_START = 'REGISTER_ATTACHMENTS_START'
export const REGISTER_ATTACHMENTS_SUCCESS = 'REGISTER_ATTACHMENTS_SUCCESS'
export const REGISTER_ATTACHMENTS_FAIL = 'REGISTER_ATTACHMENTS_FAIL'

export const CLEAR_COMMENT_ATTACHMENTS = 'CLEAR_COMMENT_ATTACHMENTS'
export const CLEAR_MESSAGE_ATTACHMENTS = 'CLEAR_MESSAGE_ATTACHMENTS'

export const LOAD_ACTUAL_START = 'LOAD_ACTUAL_START'
export const LOAD_ACTUAL_SUCCESS = 'LOAD_ACTUAL_SUCCESS'
export const LOAD_ACTUAL_FAIL = 'LOAD_ACTUAL_FAIL'
export const REFRESH_ACTUAL_SUCCESS = 'REFRESH_ACTUAL_SUCCESS'

export const LOAD_ACTIVITY_LOG_START = 'LOAD_ACTIVITY_LOG_START'
export const LOAD_ACTIVITY_LOG_SUCCESS = 'LOAD_ACTIVITY_LOG_SUCCESS'
export const LOAD_ACTIVITY_LOG_FAIL = 'LOAD_ACTIVITY_LOG_FAIL'

export const LOAD_MESSAGES_START = 'LOAD_MESSAGES_START'
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS'
export const LOAD_MESSAGES_FAIL = 'LOAD_MESSAGES_FAIL'

export const LOAD_ATTACHMENTS_START = 'LOAD_ATTACHMENTS_START'
export const LOAD_ATTACHMENTS_SUCCESS = 'LOAD_ATTACHMENTS_SUCCESS'
export const LOAD_ATTACHMENTS_FAIL = 'LOAD_ATTACHMENTS_FAIL'

export const LOAD_TRANSACTIONS_START = 'LOAD_TRANSACTIONS_START'
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS'
export const LOAD_TRANSACTIONS_FAIL = 'LOAD_TRANSACTIONS_FAIL'

export const LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_START = 'LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_START'
export const LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_SUCCESS = 'LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_SUCCESS'
export const LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_FAIL = 'LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_FAIL'

export const SEND_MESSAGE_START = 'SEND_MESSAGE_START'
export const SEND_MESSAGE_FAIL = 'SEND_MESSGE_FAIL'
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'

export const REMOVE_BUFFERED_MESSAGE_ATTACHMENT = 'REMOVE_BUFFERED_MESSAGE_ATTACHMENT'
export const REMOVE_BUFFERED_COMMENT_ATTACHMENT = 'REMOVE_BUFFERED_COMMENT_ATTACHMENT'
export const REMOVE_SAVED_ATTACHMENT = 'REMOVE_SAVED_ATTACHMENT'

export const DELETE_ATTACHMENT_START = 'DELETE_ATTACHMENT_START'
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS'
export const DELETE_ATTACHMENT_FAIL = 'DELETE_ATTACHMENT_FAIL'

export const SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_START = 'SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_START'
export const SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_SUCCESS = 'SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_SUCCESS'
export const SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_FAIL = 'SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_FAIL'

export const DELETE_SAVED_ATTACHMENT = 'DELETE_SAVED_ATTACHMENT'

export const SEND_TABLE_ASSIGNMENT_START = 'SEND_TABLE_ASSIGNMENT_START'
export const SEND_TABLE_ASSIGNMENT_SUCCESS = 'SEND_TABLE_ASSIGNMENT_SUCCESS'
export const SEND_TABLE_ASSIGNMENT_FAIL = 'SEND_TABLE_ASSIGNMENT_FAIL'

export const SEND_STATUS_ASSIGNMENT_START = 'SEND_STATUS_ASSIGNMENT_START'
export const SEND_STATUS_ASSIGNMENT_SUCCESS = 'SEND_STATUS_ASSIGNMENT_SUCCESS'
export const SEND_STATUS_ASSIGNMENT_FAIL = 'SEND_STATUS_ASSIGNMENT_FAIL'

export const SET_VIEW_VENUE = 'SET_VIEW_VENUE'
export const SELECT_ACTIVITY_LOG_COMMENTS_MESSAGING_TAB = 'SELECT_ACTIVITY_LOG_COMMENTS_MESSAGING_TAB'

export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'

export const BOOK_CHANGE_SEARCH_VENUES = 'BOOK_CHANGE_SEARCH_VENUES'
export const BOOK_AVAILABILITY_CHANGE_SEARCH_RESULTS_PAGE = 'BOOK_AVAILABILITY_CHANGE_SEARCH_RESULTS_PAGE'
export const BOOK_TOGGLE_STEP = 'BOOK_TOGGLE_STEP'
export const BOOK_CLEAR_SCROLL_INTO_VIEW = 'BOOK_CLEAR_SCROLL_INTO_VIEW'
export const BOOK_CHANGE_BOOK_STEP_HEIGHT = 'BOOK_CHANGE_BOOK_STEP_HEIGHT'
export const BOOK_FORM_VALIDATED = 'BOOK_FORM_VALIDATED'
export const BOOK_DISMISS_ERROR_DISPLAY = 'BOOK_DISMISS_ERROR_DISPLAY'
export const BOOK_CLICK_BOOK_RESERVATION = 'BOOK_CLICK_BOOK_RESERVATION'
export const BOOK_SUBMIT_RESERVATION_START = 'BOOK_SUBMIT_RESERVATION_START'
export const BOOK_SUBMIT_RESERVATION_SUCCESS = 'BOOK_SUBMIT_RESERVATION_SUCCESS'
export const BOOK_SUBMIT_RESERVATION_FAIL = 'BOOK_SUBMIT_RESERVATION_FAIL'

export const BOOK_AVAILABILITY_CHANGE_DATE = 'BOOK_AVAILABILITY_CHANGE_DATE'
export const BOOK_AVAILABILITY_CHANGE_PARTY_SIZE = 'BOOK_AVAILABILITY_CHANGE_PARTY_SIZE'
export const BOOK_AVAILABILITY_CHANGE_SHIFT = 'BOOK_AVAILABILITY_CHANGE_SHIFT'
export const BOOK_AVAILABILITY_CHANGE_SEARCH_TIME_SLOT = 'BOOK_AVAILABILITY_CHANGE_SEARCH_TIME_SLOT'
export const BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT = 'BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT'
export const BOOK_AVAILABILITY_HOLD_RESERVATION_START = 'BOOK_AVAILABILITY_HOLD_RESERVATION_START'
export const BOOK_AVAILABILITY_HOLD_RESERVATION_SUCCESS = 'BOOK_AVAILABILITY_HOLD_RESERVATION_SUCCESS'
export const BOOK_AVAILABILITY_HOLD_RESERVATION_FAILURE = 'BOOK_AVAILABILITY_HOLD_RESERVATION_FAILURE'
export const BOOK_AVAILABILITY_RELEASE_RESERVATION_HOLD_SUCCESS = 'BOOK_AVAILABILITY_RELEASE_RESERVATION_HOLD_SUCCESS'
export const BOOK_AVAILABILITY_CHANGE_EXTERNAL_AVAILABILITY_TIME_SLOT = 'BOOK_AVAILABILITY_CHANGE_EXTERNAL_AVAILABILITY_TIME_SLOT'
export const BOOK_AVAILABILITY_CHANGE_CHARGES_FROM_EXTERNAL_TIME_SLOT = 'BOOK_AVAILABILITY_CHANGE_CHARGES_FROM_EXTERNAL_TIME_SLOT'
export const BOOK_AVAILABILITY_CHANGE_DURATION = 'BOOK_AVAILABILITY_CHANGE_DURATION'
export const BOOK_AVAILABILITY_CHANGE_SEATING_AREA = 'BOOK_AVAILABILITY_CHANGE_SEATING_AREA'
export const BOOK_AVAILABILITY_CHANGE_SHOW_ACCESS_RULES = 'BOOK_AVAILABILITY_CHANGE_SHOW_ACCESS_RULES'
export const BOOK_AVAILABILITY_CHANGE_AUDIENCE = 'BOOK_AVAILABILITY_CHANGE_AUDIENCE'
export const BOOK_AVAILABILITY_CHANGE_TYPE = 'BOOK_AVAILABILITY_CHANGE_TYPE'
export const BOOK_AVAILABILITY_SET_EXPERIENCES = 'BOOK_AVAILABILITY_SET_EXPERIENCES'
export const BOOK_AVAILABILITY_ACCESS_RULES_SELECTED_TIMESLOT = 'BOOK_AVAILABILITY_ACCESS_RULES_SELECTED_TIMESLOT'
export const GET_SUCCESS_WIDGET_SETTINGS = 'GET_SUCCESS_WIDGET_SETTINGS'
export const ENABLE_LOCK_TO_AVOID_GETTING_A_NEW_RES_HOLD = 'ENABLE_LOCK_TO_AVOID_GETTING_A_NEW_RES_HOLD'

export const BOOK_AVAILABILITY_GET_TIMES_START = 'BOOK_AVAILABILITY_GET_TIMES_START'
export const BOOK_AVAILABILITY_GET_TIMES_SUCCESS = 'BOOK_AVAILABILITY_GET_TIMES_SUCCESS'
export const BOOK_AVAILABILITY_GET_TIMES_FAIL = 'BOOK_AVAILABILITY_GET_TIMES_FAIL'
export const BOOK_AVAILABILITY_GET_TIMES_NOT_READY = 'BOOK_AVAILABILITY_GET_TIMES_NOT_READY'

export const BOOK_AVAILABILITY_GET_SHIFTS_START = 'BOOK_AVAILABILITY_GET_SHIFTS_START'
export const BOOK_AVAILABILITY_GET_SHIFTS_SUCCESS = 'BOOK_AVAILABILITY_GET_SHIFTS_SUCCESS'
export const BOOK_AVAILABILITY_GET_SHIFTS_FAIL = 'BOOK_AVAILABILITY_GET_SHIFTS_FAIL'

export const BOOK_AVAILABILITY_GET_DAILY_EVENTS_START = 'BOOK_AVAILABILITY_GET_DAILY_EVENTS_START'
export const BOOK_AVAILABILITY_GET_DAILY_EVENTS_SUCCESS = 'BOOK_AVAILABILITY_GET_DAILY_EVENTS_SUCCESS'
export const BOOK_AVAILABILITY_GET_DAILY_EVENTS_FAIL = 'BOOK_AVAILABILITY_GET_DAILY_EVENTS_FAIL'

export const BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_START = 'BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_START'
export const BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_SUCCESS = 'BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_SUCCESS'
export const BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_FAIL = 'BOOK_AVAILABILITY_GET_SEATINGAREA_TABLES_FAIL'

export const BOOK_AVAILABILITY_TIMES_DATA_READY = 'BOOK_AVAILABILITY_TIMES_DATA_READY'

export const BOOK_CLIENT_CHANGE_SEARCH_TERMS = 'BOOK_CLIENT_CHANGE_SEARCH_TERMS'
export const BOOK_CLIENT_CHANGE_SELECTED_HOTEL_ID = 'BOOK_CLIENT_CHANGE_SELECTED_HOTEL_ID'
export const BOOK_CLIENT_SELECT_EXISTING_CLIENT = 'BOOK_CLIENT_SELECT_EXISTING_CLIENT'
export const BOOK_CLIENT_CHANGE_SELECTED_CLIENT = 'BOOK_CLIENT_CHANGE_SELECTED_CLIENT'
export const BOOK_CLIENT_DESELECT_CLIENT = 'BOOK_CLIENT_DESELECT_CLIENT'
export const BOOK_CLIENT_DESELECT_SOURCE_CLIENT = 'BOOK_CLIENT_DESELECT_SOURCE_CLIENT'
export const BOOK_CLIENT_SELECT_HOTEL_CLIENT = 'BOOK_CLIENT_SELECT_HOTEL_CLIENT'
export const BOOK_CLIENT_CHANGE_CLIENT_FIELD = 'BOOK_CLIENT_CHANGE_CLIENT_FIELD'
export const BOOK_CLIENT_CHANGE_CUSTOM_UNINDEXED_FIELD = 'BOOK_CLIENT_CHANGE_CUSTOM_UNINDEXED_FIELD'
export const BOOK_CLIENT_CHANGE_PHONE_FIELD = 'BOOK_CLIENT_CHANGE_PHONE_FIELD'
export const BOOK_CLIENT_CHANGE_ALT_PHONE_FIELD = 'BOOK_CLIENT_CHANGE_ALT_PHONE_FIELD'
export const BOOK_CLIENT_CHANGE_CLIENT_TAGS = 'BOOK_CLIENT_CHANGE_CLIENT_TAGS'
export const BOOK_CLIENT_TOGGLE_SHOW_MORE_CLIENT_FIELDS = 'BOOK_CLIENT_TOGGLE_SHOW_MORE_CLIENT_FIELDS'

export const BOOK_CLIENT_GET_CLIENT_LOOKUP_START = 'BOOK_CLIENT_GET_CLIENT_LOOKUP_START'
export const BOOK_CLIENT_GET_CLIENT_LOOKUP_SUCCESS = 'BOOK_CLIENT_GET_CLIENT_LOOKUP_SUCCESS'
export const BOOK_CLIENT_GET_CLIENT_LOOKUP_FAIL = 'BOOK_CLIENT_GET_CLIENT_LOOKUP_FAIL'

export const BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_START = 'BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_START'
export const BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_SUCCESS = 'BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_SUCCESS'
export const BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_FAIL = 'BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_FAIL'

export const BOOK_CLIENT_GET_CLIENT_HYDRATED_START = 'BOOK_CLIENT_GET_CLIENT_HYDRATED_START'
export const BOOK_CLIENT_GET_CLIENT_HYDRATED_SUCCESS = 'BOOK_CLIENT_GET_CLIENT_HYDRATED_SUCCESS'
export const BOOK_CLIENT_GET_CLIENT_HYDRATED_FAIL = 'BOOK_CLIENT_GET_CLIENT_HYDRATED_FAIL'

export const BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_START = 'BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_START'
export const BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_SUCCESS = 'BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_SUCCESS'
export const BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_FAIL = 'BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_FAIL'

export const BOOK_SOURCE_CHANGE_SEARCH_TERMS = 'BOOK_SOURCE_CHANGE_SEARCH_TERMS'
export const BOOK_SOURCE_SELECT_EXISTING_SOURCE = 'BOOK_SOURCE_SELECT_EXISTING_SOURCE'
export const BOOK_SOURCE_CHANGE_SELECTED_SOURCE = 'BOOK_SOURCE_CHANGE_SELECTED_SOURCE'
export const BOOK_SOURCE_CHANGE_SOURCE_FIELD = 'BOOK_SOURCE_CHANGE_SOURCE_FIELD'
export const BOOK_SOURCE_CHANGE_CUSTOM_UNINDEXED_FIELD = 'BOOK_SOURCE_CHANGE_CUSTOM_UNINDEXED_FIELD'
export const BOOK_SOURCE_CHANGE_SOURCE_TAGS = 'BOOK_SOURCE_CHANGE_SOURCE_TAGS'
export const BOOK_SOURCE_TOGGLE_SHOW_MORE_SOURCE_FIELDS = 'BOOK_SOURCE_TOGGLE_SHOW_MORE_SOURCE_FIELDS'

export const BOOK_SOURCE_GET_SOURCE_LOOKUP_START = 'BOOK_SOURCE_GET_SOURCE_LOOKUP_START'
export const BOOK_SOURCE_GET_SOURCE_LOOKUP_SUCCESS = 'BOOK_SOURCE_GET_SOURCE_LOOKUP_SUCCESS'
export const BOOK_SOURCE_GET_SOURCE_LOOKUP_FAIL = 'BOOK_SOURCE_GET_SOURCE_LOOKUP_FAIL'

export const BOOK_SOURCE_GET_SOURCE_HYDRATED_START = 'BOOK_SOURCE_GET_SOURCE_HYDRATED_START'
export const BOOK_SOURCE_GET_SOURCE_HYDRATED_SUCCESS = 'BOOK_SOURCE_GET_SOURCE_HYDRATED_SUCCESS'
export const BOOK_SOURCE_GET_SOURCE_HYDRATED_FAIL = 'BOOK_SOURCE_GET_SOURCE_HYDRATED_FAIL'

export const UPGRADES_CHANGE_FORM = 'UPGRADES_CHANGE_FORM'

export const PUSH_PAYMENT_TRANSACTION = 'PUSH_PAYMENT_TRANSACTION'
export const PAYMENT_CHANGE_CARD_ENTRY_OPTION = 'PAYMENT_CHANGE_CARD_ENTRY_OPTION'
export const PAYMENT_CHANGE_CARD_HOLDER_NAME = 'PAYMENT_CHANGE_CARD_HOLDER_NAME'
export const PAYMENT_CHANGE_CARD_HOLDER_NUMBER = 'PAYMENT_CHANGE_CARD_HOLDER_NUMBER'
export const PAYMENT_CHANGE_CARD_EXP_MONTH = 'PAYMENT_CHANGE_CARD_EXP_MONTH'
export const PAYMENT_CHANGE_CARD_EXP_YEAR = 'PAYMENT_CHANGE_CARD_EXP_YEAR'
export const PAYMENT_CHANGE_CARD_CCV = 'PAYMENT_CHANGE_CARD_CCV'
export const PAYMENT_CHANGE_TAKE_PAYMENT_OR_SAVE = 'PAYMENT_CHANGE_TAKE_PAYMENT_OR_SAVE'
export const PAYMENT_CHANGE_CHARGE_AMOUNT = 'PAYMENT_CHANGE_CHARGE_AMOUNT'
export const PAYMENT_CHANGE_CHARGE_GRATUITY = 'PAYMENT_CHANGE_CHARGE_GRATUITY'
export const PAYMENT_CHANGE_CHARGE_APPLY_TAX = 'PAYMENT_CHANGE_CHARGE_APPLY_TAX'
export const PAYMENT_CHANGE_TAX_GROUP_ID = 'PAYMENT_CHANGE_TAX_GROUP_ID'
export const PAYMENT_CHANGE_APPLY_SERVICE_CHARGE = 'PAYMENT_CHANGE_APPLY_SERVICE_CHARGE'
export const PAYMENT_CHANGE_SERVICE_CHARGE = 'PAYMENT_CHANGE_SERVICE_CHARGE'
export const PAYMENT_CHANGE_APPLY_GRATUITY_CHARGE = 'PAYMENT_CHANGE_APPLY_GRATUITY_CHARGE'
export const PAYMENT_CHANGE_GRATUITY_CHARGE = 'PAYMENT_CHANGE_GRATUITY_CHARGE'
export const PAYMENT_CHANGE_PAYMENTS_FORM = 'PAYMENT_CHANGE_PAYMENTS_FORM'
export const PAYMENT_CHANGE_PAYLINK_GRATUITY_TYPE = 'PAYMENT_CHANGE_PAYLINK_GRATUITY_TYPE'
export const PAYMENT_CHANGE_CHARGE_DESCRIPTION = 'PAYMENT_CHANGE_CHARGE_DESCRIPTION'
export const PAYMENT_CHANGE_CHARGE_SEND_NOTIFICATION = 'PAYMENT_CHANGE_CHARGE_SEND_NOTIFICATION'
export const PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL = 'PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL'
export const PAYMENT_CHANGE_OVERRIDE = 'PAYMENT_CHANGE_OVERRIDE'
export const PAYMENT_CHANGE_USE_GUEST_PROFILE_PHONE_NUMBER = 'PAYMENT_CHANGE_USE_GUEST_PROFILE_PHONE_NUMBER'
export const PAYMENT_CHANGE_PHONE_NUMBER = 'PAYMENT_CHANGE_PHONE_NUMBER'
export const PAYMENT_CHANGE_PHONE_COUNTRY = 'PAYMENT_CHANGE_PHONE_COUNTRY'
export const PAYMENT_CHANGE_PAYLINK_AUTO_CANCEL = 'PAYMENT_CHANGE_PAYLINK_AUTO_CANCEL'
export const RESTORE_SHIFT_CHARGES = 'RESTORE_SHIFT_CHARGES'

export const PAYMENT_ADD_RES_CARD = 'PAYMENT_ADD_RES_CARD'
export const PAYMENT_SELECT_CARD = 'PAYMENT_SELECT_CARD'

export const PAYMENT_SELECT_CHARGE_OR_PAYLINK = 'PAYMENT_SELECT_CHARGE_OR_PAYLINK'
export const PAYMENT_TOGGLE_MODAL_NOTIFICATION = 'PAYMENT_TOGGLE_MODAL_NOTIFICATION'
export const PAYMENT_NOTIFICATION_START = 'PAYMENT_NOTIFICATION_START'
export const PAYMENT_NOTIFICATION_SUCCESS = 'PAYMENT_NOTIFICATION_SUCCESS'
export const PAYMENT_NOTIFICATION_FAIL = 'PAYMENT_NOTIFICATION_FAIL'
export const PAYMENT_SUBMIT_NOTIFICATION = 'PAYMENT_SUBMIT_NOTIFICATION'
export const PAYMENT_TOGGLE_DETAIL = 'PAYMENT_TOGGLE_DETAIL'
export const PAYMENT_TOGGLE_MODAL_CHARGE = 'PAYMENT_TOGGLE_MODAL_CHARGE'
export const PAYMENT_CHARGE_START = 'PAYMENT_CHARGE_START'
export const PAYMENT_CHARGE_SUCCESS = 'PAYMENT_CHARGE_SUCCESS'
export const PAYMENT_CHARGE_FAIL = 'PAYMENT_CHARGE_FAIL'
export const PAYMENT_SET_REFUND_ID = 'PAYMENT_SET_REFUND_ID '
export const PAYMENT_REFUND_START = 'PAYMENT_REFUND_START'
export const PAYMENT_REFUND_SUCCESS = 'PAYMENT_REFUND_SUCCESS'
export const PAYMENT_REFUND_FAIL = 'PAYMENT_REFUND_FAIL'
export const PAYMENT_PAYLINK_SUBMIT = 'PAYMENT_PAYLINK_SUBMIT'
export const PAYMENT_PAYLINK_SUCCESS = 'PAYMENT_PAYLINK_SUCCESS'
export const PAYMENT_PAYLINK_FAIL = 'PAYMENT_PAYLINK_FAIL'
export const PAYMENT_PAYLINK_DELETE_START = 'PAYMENT_PAYLINK_DELETE_START'
export const PAYMENT_PAYLINK_DELETE_SUCCESS = 'PAYMENT_PAYLINK_DELETE_SUCCESS'
export const PAYMENT_PAYLINK_DELETE_FAIL = 'PAYMENT_PAYLINK_DELETE_FAIL'

export const PAYMENT_DELETE_CARD_START = 'PAYMENT_DELETE_CARD_START'
export const PAYMENT_DELETE_CARD_SUCCESS = 'PAYMENT_DELETE_CARD_SUCCESS'
export const PAYMENT_DELETE_CARD_FAIL = 'PAYMENT_DELETE_CARD_FAIL'

export const SPEND_TOGGLE_DETAILS = 'SPEND_TOGGLE_DETAILS'
export const SPEND_TOGGLE_LIST = 'SPEND_TOGGLE_LIST'
export const FEEDBACK_TOGGLE_DETAILS = 'FEEDBACK_TOGGLE_DETAILS'

export const REFUND_CHANGE_CHARGE_AMOUNT = 'REFUND_CHANGE_CHARGE_AMOUNT '
export const REFUND_CHANGE_TYPE = 'REFUND_CHANGE_TYPE '
export const REFUND_CHANGE_DESCRIPTION = 'REFUND_CHANGE_DESCRIPTION '
export const REFUND_TOGGLE_NOTIFICATION = 'REFUND_TOGGLE_NOTIFICATION '
export const REFUND_TOGGLE_REFUND_GIFT_CARD = 'REFUND_TOGGLE_REFUND_GIFT_CARD'

export const CHARGE_FORM_VALIDATED = 'CHARGE_FORM_VALIDATED'

export const BOOK_DETAILS_CHANGE_RESERVATION_NOTES = 'BOOK_DETAILS_CHANGE_RESERVATION_NOTES'
export const BOOK_DETAILS_CHANGE_RESERVATION_TAGS = 'BOOK_DETAILS_CHANGE_RESERVATION_TAGS'
export const BOOK_DETAILS_CHANGE_SELECTED_TABLES = 'BOOK_DETAILS_CHANGE_SELECTED_TABLES'
export const BOOK_DETAILS_CHANGE_IS_CUSTOM_ASSIGN = 'BOOK_DETAILS_CHANGE_IS_CUSTOM_ASSIGN'
export const BOOK_DETAILS_CHANGE_SELECTED_BOOKED_BY = 'BOOK_DETAILS_CHANGE_SELECTED_BOOKED_BY'
export const BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY = 'BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY'
export const BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY_SLOT = 'BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY_SLOT'
export const BOOK_DETAILS_CHANGE_ADDITIONAL_BOOKED_BY = 'BOOK_DETAILS_CHANGE_ADDITIONAL_BOOKED_BY'
export const BOOK_DETAILS_CHANGE_CUSTOM_BOOKED_BY_NAME = 'BOOK_DETAILS_CHANGE_CUSTOM_BOOKED_BY_NAME'
export const BOOK_DETAILS_ENABLE_CUSTOM_BOOKED_BY_NAME = 'BOOK_DETAILS_ENABLE_CUSTOM_BOOKED_BY_NAME'
export const BOOK_DETAILS_REMOVE_ADDITIONAL_BOOKED_BY = 'BOOK_DETAILS_REMOVE_ADDITIONAL_BOOKED_BY'
export const BOOK_DETAILS_REMOVE_UNFILLED_ADDITIONAL_BOOKED_BY = 'BOOK_DETAILS_REMOVE_UNFILLED_ADDITIONAL_BOOKED_BY'
export const BOOK_DETAILS_CHANGE_MESSAGING_FIELD = 'BOOK_DETAILS_CHANGE_MESSAGING_FIELD'
export const BOOK_DETAILS_CHANGE_PERKS_FIELD = 'BOOK_DETAILS_CHANGE_PERKS_FIELD'
export const BOOK_DETAILS_SET_SELECTED_COST_OPTION = 'BOOK_DETAILS_SET_SELECTED_COST_OPTION'
export const BOOK_DETAILS_CHANGE_SELECTED_COST_AMOUNT = 'BOOK_DETAILS_CHANGE_SELECTED_COST_AMOUNT'
export const BOOK_DETAILS_CHANGE_CUSTOM_INDEXED_FIELD = 'BOOK_DETAILS_CHANGE_CUSTOM_INDEXED_FIELD'
export const BOOK_DETAILS_CHANGE_CUSTOM_UNINDEXED_FIELD = 'BOOK_DETAILS_CHANGE_CUSTOM_UNINDEXED_FIELD'

export const BOOK_DETAILS_GET_BOOKED_BY_NAMES_START = 'BOOK_DETAILS_GET_BOOKED_BY_NAMES_START'
export const BOOK_DETAILS_GET_BOOKED_BY_NAMES_SUCCESS = 'BOOK_DETAILS_GET_BOOKED_BY_NAMES_SUCCESS'
export const BOOK_DETAILS_GET_BOOKED_BY_NAMES_FAIL = 'BOOK_DETAILS_GET_BOOKED_BY_NAMES_FAIL'

export const BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_START = 'BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_START'
export const BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_SUCCESS = 'BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_SUCCESS'
export const BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_FAIL = 'BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_FAIL'

export const COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS = 'COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS'

export const DELETE_FOLLOWER_START = 'DELETE_FOLLOWER_START'
export const DELETE_FOLLOWER_SUCCESS = 'DELETE_FOLLOWER_SUCCESS'
export const DELETE_FOLLOWER_FAIL = 'DELETE_FOLLOWER_FAIL'

export const ADD_FOLLOWER_START = 'ADD_FOLLOWER_START'
export const ADD_FOLLOWER_SUCCESS = 'ADD_FOLLOWER_SUCCESS'
export const ADD_FOLLOWER_FAIL = 'ADD_FOLLOWER_FAIL'

export const RESET_BOOKED_BY_NAMES = 'RESET_BOOKED_BY_NAMES'
export const ON_BEFORE_PAGE_UNLOAD = 'ON_BEFORE_PAGE_UNLOAD'
export const DISMISS_UNSAVED_WARNING = 'DISMISS_UNSAVED_WARNING'
export const IGNORE_UNSAVED_WARNING = 'IGNORE_UNSAVED_WARNING'
export const DISPLAY_HIGHER_CHARGE_WARNING = 'DISPLAY_HIGHER_CHARGE_WARNING'
export const DISMISS_HIGHER_CHARGE_WARNING = 'DISMISS_HIGHER_CHARGE_WARNING'
export const IGNORE_HIGHER_CHARGE_WARNING = 'IGNORE_HIGHER_CHARGE_WARNING'

export const DISPLAY_MISSING_EMAIL_WARNING = 'DISPLAY_MISSING_EMAIL_WARNING'
export const DISMISS_MISSING_EMAIL_WARNING = 'DISMISS_MISSING_EMAIL_WARNING'
export const IGNORE_MISSING_EMAIL_WARNING = 'IGNORE_MISSING_EMAIL_WARNING'

export const VIEW_ACTUAL_CHANGE_SELECTED_TABLES = 'VIEW_ACTUAL_CHANGE_SELECTED_TABLES'
export const VIEW_ACTUAL_CHANGE_IS_CUSTOM_ASSIGN = 'VIEW_ACTUAL_CHANGE_IS_CUSTOM_ASSIGN'
export const VIEW_ACTUAL_TABLE_CHANGE_SAVED = 'VIEW_ACTUAL_TABLE_CHANGE_SAVED'

export const ENABLE_LINKING = 'ENABLE_LINKING'
export const LINK_REVIEW_START = 'LINK_REVIEW_START'
export const LINK_REVIEW_SUCCESS = 'LINK_REVIEW_SUCCESS'
export const LINK_REVIEW_FAIL = 'LINK_REVIEW_FAIL'
export const UNLINK_REVIEW_START = 'UNLINK_REVIEW_START'
export const UNLINK_REVIEW_SUCCESS = 'UNLINK_REVIEW_SUCCESS'
export const UNLINK_REVIEW_FAIL = 'UNLINK_REVIEW_FAIL'
export const GET_REVIEWS_START = 'GET_REVIEWS_START'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const SCROLL_ON_LOAD = 'SCROLL_ON_LOAD'

export const PASS_STRIPE_CARD_ELEMENT = 'PASS_STRIPE_CARD_ELEMENT'

export const SET_SAFERPAY_INITIAL_DATA = 'SET_SAFERPAY_INITIAL_DATA'
export const SET_ADYEN_INITIAL_DATA = 'SET_ADYEN_INITIAL_DATA'

export const SHOW_REMOVE_PAYLINK_MODAL = 'SHOW_REMOVE_PAYLINK_MODAL'
export const TOGGLE_BUFFER = 'TOGGLE_BUFFER'
export const RESEND_BOOKING_NOTIFICATION_START = 'RESEND_BOOKING_NOTIFICATION_START'
export const RESEND_BOOKING_NOTIFICATION_SUCCESS = 'RESEND_BOOKING_NOTIFICATION_SUCCESS'
export const RESEND_BOOKING_NOTIFICATION_FAIL = 'RESEND_BOOKING_NOTIFICATION_FAIL'
export const SET_EXCLUDE_FROM_SHIFT_PACING = 'SET_EXCLUDE_FROM_SHIFT_PACING'
